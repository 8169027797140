import 'bootstrap/dist/js/bootstrap.min';

$(document).ready(() => {
    var $btnMoreInformation = $('.gtcookies-more-information');
    var $gtcookiesModal = $('#gtcookies-modal');

    $('body').on('click', '.gtcookies-more-information', function(e) {
        $gtcookiesModal.toggleClass('open');
        e.preventDefault();
    });
});